export default function showNavigationWhenScrollingUp() {
  let previousScrollPosition = window.scrollY;
  const body = document.querySelector("body");
  window.addEventListener("scroll", () => {
    const resetPosition = 0;
    const start = 100;
    const currentScrollPosition = window.scrollY;

    if (previousScrollPosition > currentScrollPosition || currentScrollPosition < start) {
      document.querySelector(".navigation__nav").style.transform = "translateY(0)";
      body.setAttribute("data-nav-open", "true");
    } else {
      document.querySelector(".navigation__nav").style.transform = "translateY(calc(-100% - 1px))";
      body.setAttribute("data-nav-open", "false");
    }
    if (currentScrollPosition <= resetPosition + start) {
      document.querySelector(".navigation__nav").classList.remove("navigation__nav--scrolling-up");
    } else {
      document.querySelector(".navigation__nav").classList.add("navigation__nav--scrolling-up");
    }
    previousScrollPosition = currentScrollPosition;
  });
}
