export default function videoOverlay() {
  const videoWrappers = document.querySelectorAll(".media__video-wrapper");

  videoWrappers.forEach((videoWrapper) => {
    videoWrapper.addEventListener("click", () => {
      const video = videoWrapper.querySelector(".media__video");
      if (video.paused) {
        video.play();
        videoWrapper.classList.add("media__video-wrapper--playing");
      } else {
        video.pause();
        videoWrapper.classList.remove("media__video-wrapper--playing");
      }
    });
  });
}
