export default function team() {
  const teams = document.querySelectorAll(".team__members");

  teams.forEach((teamElement) => {
    const members = teamElement.querySelectorAll(".team__member");
    members.forEach((member) => {
      const showMoreButton = member.querySelector(".team__member-show-more");
      const showLessButton = member.querySelector(".team__member-show-less");
      const biography = member.querySelector(".team__member-bio");
      const overlay = document.querySelector(".team-overlay");

      biography.classList.add("team__member-bio--collapsed");

      showMoreButton.addEventListener("click", () => {
        biography.classList.remove("team__member-bio--collapsed");
        biography.classList.add("team__member-bio--expanded");
        showMoreButton.classList.add("team__member-show-more--hidden");
        document.body.classList.add("no-scroll");
        overlay.classList.add("team-overlay--visible");
      });

      showLessButton.addEventListener("click", () => {
        biography.classList.add("team__member-bio--collapsed");
        biography.classList.remove("team__member-bio--expanded");
        showMoreButton.classList.remove("team__member-show-more--hidden");
        document.body.classList.remove("no-scroll");
        overlay.classList.remove("team-overlay--visible");
      });
    });
  });
}
