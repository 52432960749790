export default function homeStage() {
  const navButtons = document.querySelectorAll(".home-stage__nav-button");
  const slides = document.querySelectorAll(".home-stage__slide");
  const navigation = document.querySelector(".home-stage__navigation");

  const handleVideoVolume = () => {
    const volumeOffIcon = `
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none">
      <path d="M16.5 12C16.5 10.23 15.48 8.71 14 7.97V10.18L16.45 12.63C16.48 12.43 16.5 12.22 16.5 12ZM19 12C19 12.94 18.8 13.82 18.46 14.64L19.97 16.15C20.63 14.91 21 13.5 21 12C21 7.72 18.01 4.14 14 3.23V5.29C16.89 6.15 19 8.83 19 12ZM4.27 3L3 4.27L7.73 9H3V15H7L12 20V13.27L16.25 17.52C15.58 18.04 14.83 18.45 14 18.7V20.76C15.38 20.45 16.63 19.81 17.69 18.95L19.73 21L21 19.73L12 10.73L4.27 3ZM12 4L9.91 6.09L12 8.18V4Z" fill="currentColor"/>
    </svg>`;
    const volumeOnIcon = `
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none">
      <path d="M3 9.99999V14C3 14.55 3.45 15 4 15H7L10.29 18.29C10.92 18.92 12 18.47 12 17.58V6.40999C12 5.51999 10.92 5.06999 10.29 5.69999L7 8.99999H4C3.45 8.99999 3 9.44999 3 9.99999ZM16.5 12C16.5 10.23 15.48 8.70999 14 7.96999V16.02C15.48 15.29 16.5 13.77 16.5 12ZM14 4.44999V4.64999C14 5.02999 14.25 5.35999 14.6 5.49999C17.18 6.52999 19 9.05999 19 12C19 14.94 17.18 17.47 14.6 18.5C14.24 18.64 14 18.97 14 19.35V19.55C14 20.18 14.63 20.62 15.21 20.4C18.6 19.11 21 15.84 21 12C21 8.15999 18.6 4.88999 15.21 3.59999C14.63 3.36999 14 3.81999 14 4.44999Z" fill="currentColor"/>
    </svg>`;

    const videoBlocks = document.querySelectorAll(".home-stage__media");

    videoBlocks.forEach((videoBlock) => {
      const video = videoBlock.querySelector(".media__video");
      const videoButton = videoBlock.querySelector(".home-stage__video-control");
      let muted = true;
      if (video && videoButton) {
        videoButton.addEventListener("click", () => {
          muted = !muted;
          if (muted) {
            videoButton.innerHTML = volumeOffIcon;
            videoButton.classList.remove("home-stage__video-control--active");
            video.muted = true;
          } else {
            videoButton.innerHTML = volumeOnIcon;
            videoButton.classList.add("home-stage__video-control--active");
            video.muted = false;
          }
        });
      }
    });
  };

  function showMessages(slideIndex) {
    const slide = slides[slideIndex];

    if (!slide) {
      return;
    }

    const messages = slide.querySelectorAll(".chat__message");
    let delay = 0;

    messages.forEach((message, index) => {
      setTimeout(() => {
        if (index % 2 === 0) {
          message.classList.add("show-left");
        } else {
          message.classList.add("show-right");
        }
      }, delay);
      delay += 800;
    });
  }

  function loadVideo(slideIndex) {
    slides.forEach((slide, index) => {
      const videoElement = slide.querySelector(".media__video");
      if (videoElement) {
        if (index === slideIndex) {
          setTimeout(() => {
            videoElement.currentTime = 0; // Restart the video
            videoElement.play().catch((error) => {
              console.error("Error attempting to play the video:", error);
            });
          }, 100); // Small delay before playing
        } else {
          videoElement.pause();
        }
      }
    });
  }

  function handleInteraction() {
    showMessages(0);
    loadVideo(0);
    document.removeEventListener("click", handleInteraction);
    document.removeEventListener("scroll", handleScroll);
  }

  function handleScroll() {
    const homeStageElement = document.querySelector(".home-stage");

    if (!homeStageElement) {
      return;
    }

    const homeStageRect = homeStageElement.getBoundingClientRect();
    const windowHeight = window.innerHeight;

    if (homeStageRect.top < windowHeight && homeStageRect.bottom > 0) {
      handleInteraction();
    }
  }

  navButtons.forEach((navButton) => {
    navButton.addEventListener("click", () => {
      const slideIndex = parseInt(navButton.getAttribute("data-slide-index"), 10);

      slides.forEach((slide) => {
        slide.classList.remove("home-stage__slide--active");
        const messages = slide.querySelectorAll(".chat__message");
        messages.forEach((message) => {
          message.classList.remove("show-left");
          message.classList.remove("show-right");
        });
      });

      navButtons.forEach((_navButton) => _navButton.classList.remove("home-stage__nav-button--active"));

      slides[slideIndex].classList.add("home-stage__slide--active");
      navButton.classList.add("home-stage__nav-button--active");

      navigation.style.setProperty("--active-index", slideIndex);

      showMessages(slideIndex);
      loadVideo(slideIndex);
    });
  });

  // Load the initial video after user interaction or scroll
  document.addEventListener("click", handleInteraction, { once: true });
  document.addEventListener("scroll", handleScroll);

  handleVideoVolume();
}
